.fade-slide-enter {
  opacity: 0;
  transform: translateX(100%);
  z-index: 1;
}

.fade-slide-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms ease-in;
}

.fade-slide-exit {
  opacity: 1;
  transform: translateX(-100%);
  position: absolute;
}

.fade-slide-exit-active {
  opacity: 0;
  transform: translateX(-100%);
  transition: opacity 300ms ease-in;
}