.slick-prev:before,
.slick-next:before {
  color: #ff4500; /* Orange color for arrows */
  visibility: hidden;
}

.slick-dots li button:before {
  color: #ff4500; /* Orange color for dots */
  visibility: hidden;
}

.slick-slide {
  padding: 0 10px; /* Add some spacing between slides */
}

/* Ensure the slider doesn't overflow the container */
.slick-list {
  margin: 0 -10px;
}