body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary-color: rgb(237, 71, 33);
  --primary-color-10: rgba(237, 71, 33, 0.1);
  --primary-color-30: rgba(237, 71, 33, 0.3);
  --primary-color-60: rgba(237, 71, 33, 0.6);
}

#content {
  position: relative;
  z-index: 1;
  color: #fff;
  text-align: center;
  padding-top: 50px;
}

#particles-js {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
}