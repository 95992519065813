/* src/styles.css */
.underline-animation {
    position: relative;
    display: inline-block;
}

.underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: -0.2rem;
    left: 0;
    background-color: var(--primary-color);;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
    border-radius: 999px;
}

.underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.underline-stable {
    position: relative;
    display: inline-block;
    color: var(--primary-color);
}

.underline-stable::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: -0.2rem;
    left: 0;
    background-color: var(--primary-color);
    border-radius: 2px;
    /* 圆角效果 */
    transform: scaleX(1);
    transform-origin: bottom left;
    transition: transform 0.25s ease-out;
}